import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import qs from 'query-string';
import EmeaFilterBy from 'components/EMEA/EmeaFilterBy';
import ErrorHandlerPage from 'components/common/ErrorHandlerPage';
import LoadingPage from 'components/common/LoadingPage';
import { updateCountries, emeaGetLocationsService } from 'reduxLib/services/EmeaServices/emeaLocationServices';
import { emeaGetAllFavService } from 'reduxLib/services/EmeaServices/emeaFavoriteService';
// import EMEAShowTabsBy from 'components/EMEA/EMEAShowTabsBy';
import EMEATabsWrapper from 'components/EMEA/EMEATabsWrapper';
import EMEAGlobalChips from './EMEAGlobalChips';
import { businessUnits, defaultDocumentDate, getDocumentDate } from "configs/appConstants";
import EMEAGlobalContext from 'context/EMEAGlobalContext';
import useSetQuery from 'customHooks/useQuery';
import { generatePayload } from 'helpers/emeaHelpers';
import EMEADateRangePicker from 'components/EMEA/EMEA_Common/EMEADateRangePicker';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(8),
    position: 'relative',
    height: '100%',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(7)
    }
  },
  body: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      marginTop: theme.spacing(.5),
    }
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(6),
    marginTop: theme.spacing(10)
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    fontSize: 14,
    textDecoration: 'underline'
  },
  progress: {
    backgroundColor: theme.palette?.text?.primary
  },
  mainGrid: {
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(2.5)}px 0px ${theme.spacing(1)}px`
    }
  }
}))

const EmeaMainLayout = ({ component, region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [params] = useSetQuery();
  const { tab } = useParams();
  const pageFromUrl = location?.pathname.split('/')[4];
  const [defaultDocDate] = useState(defaultDocumentDate);
  const [urlSearch] = useState(location.search);

  const { locations_err, locations_loading, emea_all_sites, locations_fav_loading } = useSelector(({ emeaSites }) => emeaSites);
  const { all_favs_loading, all_favs, bu_fav_loading } = useSelector(({ emeaGlobalFavs }) => emeaGlobalFavs);
  const { customer_search_loading, cust_fav_loading } = useSelector(({ emeaCustomers }) => emeaCustomers);
  const { sales_ofc_loading, sosg_fav_loading } = useSelector(({ emeaSalesOfc }) => emeaSalesOfc);

  const type = 'emea';
  const tableName = 'custReturns';
  const pageList = ['returns', 'orderEntry', 'openOrders', 'orderCutsRejections','invoices'];

  useEffect(() => {
    !locations_loading && dispatch(updateCountries());
  }, [locations_loading])

  useEffect(() => {
    !locations_loading && !isEmpty(emea_all_sites) && dispatch(emeaGetAllFavService());
  }, [emea_all_sites, locations_loading])

  useEffect(() => {
    if (isEmpty(urlSearch) && !all_favs_loading && !isEmpty(all_favs)) {
      const BU = all_favs?.favouriteBusinessUnits || businessUnits;
      const ctry = [];
      const sites = all_favs?.favouriteSiteNums || [];
      const Cust = all_favs?.favouriteCustomers || [];
      const sofcNSG = all_favs?.favouriteSalesOffices || [];
      const Sofc = [];
      const SG = {};

      const x = all_favs?.favouriteSalesOffices?.map(d => {
        const name = d?.split('_');
        if (name?.length > 4) {
          const so = [name[0], name[1], name[2], name[3]].join('_');
          if (has(SG, so)) {
            SG[so].push(name[4]);
          } else {
            SG[so] = [name[4]];
          }
          if (!Sofc.includes(so)) {
            Sofc.push(so)
          }
        } else Sofc.push(d);
      });

      const tab = sites?.length > 0 ? "myselections": "network";

      history.replace({
        ...location,
        pathname: `/dashboard/EMEA/${tab}/home`,
        search: qs.stringify({
          ...params,
          BU: JSON.stringify(BU),
          documentDate: JSON.stringify(defaultDocDate),
          emeaFilters: "{}",
          ctry: JSON.stringify(ctry),
          sites: JSON.stringify(sites),
          Cust: JSON.stringify(Cust),
          Sofc: JSON.stringify(Sofc),
          SG: JSON.stringify(SG),
          sofcNSG: JSON.stringify(sofcNSG),
        })
      });
    }
  }, [all_favs_loading, all_favs]);

  const [memoizedValue, setMemoizedValue] = useState(null);

  useDeepCompareEffect(() => {
    if (params.BU) {
      const { locations, customers, sofcs } = generatePayload({
        ...params,
        tab
      });

      const documentDate = getDocumentDate(pageFromUrl, params.documentDate);   
      const BU = isEmpty(params.BU) ? businessUnits : params.BU;

      setMemoizedValue({
        locations,
        customers,
        sofcs,
        BU,
        documentDate
      });
    }
  }, [params.BU, params.sites, params.Cust, tab, params.sofcNSG, params.documentDate, pageFromUrl]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(emeaGetLocationsService({ controller }));
    return () => {
      controller.abort();
    }
  }, [])

  return (
    <>
      {
        <div data-testid="mainlayout" className={classes.root}>
          {(locations_fav_loading || bu_fav_loading || all_favs_loading || customer_search_loading || cust_fav_loading || sales_ofc_loading || sosg_fav_loading) &&
            <LinearProgress className={classes.progress} />
          }
          {
            locations_loading ?
              <LoadingPage />
              : locations_err ?
                <ErrorHandlerPage errTagline={locations_err === 'emea_restricted' ? t("we_are_sorry") : null} errMessage={t(locations_err)} />
                : <>
                  <Grid container alignItems='center' className={classes.mainGrid}>
                    <Grid item>
                      <EmeaFilterBy />
                    </Grid>
                    {/* <Grid item>
                      <EMEAShowTabsBy />
                    </Grid> */}
                    {pageList.includes(pageFromUrl) && <Grid item>
                      <EMEADateRangePicker 
                          title= {(pageFromUrl === 'invoices') ? t('invoice_date')  : t('document_date')}
                          type={type} 
                          subtype={tableName} 
                          filterKey="documentDate" 
                          maxMonth={((pageFromUrl === 'returns') ? 4 : (pageFromUrl === 'invoices' || pageFromUrl === 'orderCutsRejections') ? 3 : 1)}
                          key={pageFromUrl}
                      />
                    </Grid>}
                  </Grid>
                  <EMEATabsWrapper region={region} />
                  <EMEAGlobalContext.Provider value={memoizedValue}>
                    {
                      memoizedValue && <>
                        <EMEAGlobalChips />
                        <div className={classes.body} >
                          {/* All Elements in this page except headers goes here */}
                          {component}
                        </div>
                      </>
                    }
                  </EMEAGlobalContext.Provider>
                </>
          }
        </div>
      }
    </>
  )
}

export default EmeaMainLayout;