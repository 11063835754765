import React from 'react';
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux";
import { Box, Card, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import FormHelperText from '@material-ui/core/FormHelperText';
import SearchFilter from "./SearchFilter"
import CheckBoxElement from "../CheckBoxElement";
import RadioElement from "../RadioElement";
import DateFilter from "../DateFilter";
import TextCheckFilterElement from "../TextCheckFilterElement";
import OrdersCheckBoxElement from "./OrdersCheckBoxElement";
import { saveFilters, resetFilters } from "reduxLib/services";
import TextFilterElement from "../TextFilterElement";
import OriginFilter from "./OriginFilter";
import OsmDateRangePicker from 'components/osm/OsmDateRangePicker';
import OsmCustomDateRangeFilter from 'components/osm/OsmCustomDateRangeFilter';
import { filterStyles } from "theme";
import * as filters from "reduxLib/constdata/filters";
import FilterComponentContainer from './FilterComponentContainer';
import { allowedPlaces } from "helpers/index"
import NumRangeElement from '../NumRangeElement';

const useStyles = makeStyles(filterStyles);

export const Component = (props) => {
    const { t } = useTranslation();
    const filterModules = {
        text: TextFilterElement,
        checkbox: CheckBoxElement,
        radio: RadioElement,
        date: DateFilter,
        sites: OriginFilter,
        checkboxradio: CheckBoxElement,
        ordercheckbox: OrdersCheckBoxElement,
        textcheck: TextCheckFilterElement,
        daterange: OsmDateRangePicker,
        customdaterange: OsmCustomDateRangeFilter,
        numrange: NumRangeElement
    }
    const { filter, type, subtype, open, filterKey } = props;
    const FilterComponent = filterModules[props.filter.type];
    return <FilterComponent
        type={type}
        filterValues={filter}
        subtype={subtype}
        openAll={open}
        filterKey={filterKey}
        title={t(filter.name)}
        resetOthers={props.filter.type === "checkboxradio"}
        placeholder={`${t('enter')} ${t(filter.name)}`}
        filterType={props.filter.type}
        {...props} />
}

const Filters = ({ saveFilters, resetFilters, searchText, saveLoadDate, customSaveFilters, ...props }) => {
    const { type, subtype, exclude, filtertype, handleOpen } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(props.open);
    // const allowedPlaces = ["network", "mylocation"];
    const location = useLocation();
    const regionFromUrl = location?.pathname.split('/')[2];

    return (
        <div data-testid="filterMainContaner" className={props.open ? classes.show : classes.hide}>
            <Card className={classes.root} elevation={10}>
                <Box className={classes.title} alignItems="center" display="flex">
                    <Box flexGrow={1}>
                        <Typography variant="h2" component="div">{t('filter_search')}</Typography>
                    </Box>
                    <Box alignItems="center" title={t("close")}>
                        <IconButton 
                            data-testid="closefilters" 
                            aria-label="Close"  
                            size="small" 
                            onClick={handleOpen}
                        >
                            <CloseIcon 
                                fontSize="inherit" color='primary'
                            />
                        </IconButton>
                    </Box>
                </Box>
                {regionFromUrl !== 'LAO' && <><div className={classes.serchSpace}>
                    <Typography variant="h3" component="div">{t('search_text')}</Typography>
                    <SearchFilter
                        type={type}
                        subtype={subtype}
                        filterKey={"searchStringList"}
                        customSaveFilters={customSaveFilters}
                    />
                    <FormHelperText className={classes.subtitle}> {searchText} </FormHelperText>
                </div></>}
                <Grid justify="space-between" container alignItems="center" className={clsx(classes.container, classes.controls, classes.border, classes.actions)}>
                    <Grid item>
                        <Typography
                            align="left"
                            data-testid="clearall"
                            className={classes.controlText} variant="subtitle1" component="div" color="primary" onClick={() => {
                                if (typeof props.clearAllFilters === "function") {
                                    props.clearAllFilters();
                                }
                                resetFilters({
                                    filter: {}, type, subtype
                                })
                            }}>{t('clear_all')}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            onClick={() => {
                                setOpen(!open);
                            }}
                            align="left"
                            className={classes.controlText} variant="subtitle1" component="div" color="primary">{!open ? t('expand') : t('collapse')} {t('all')}</Typography>
                    </Grid>
                </Grid>
                { 
                    Object.keys(filters[filtertype]).map((filterKey, index) => {
                        if (exclude.includes(filterKey)) {
                            return null;
                        }
                        const filter = filters[filtertype][filterKey];
                        return (
                            !allowedPlaces?.includes(type) && filter?.type === 'sites'
                            ? 
                            null
                            :
                            <FilterComponentContainer
                                key={index}
                                type={type}
                                subtype={subtype}
                                openAll={open}
                                filter={filter}
                                filterKey={filterKey}
                                title={t(filter.name)}
                                testid={filter.name}
                            >
                                <Component
                                    key={index}
                                    type={type}
                                    subtype={subtype}
                                    openAll={open}
                                    filter={filter}
                                    filterKey={filterKey}
                                    testid={filter.name}
                                    customSaveFilters={customSaveFilters}
                                />
                            </FilterComponentContainer>
                        )
                    })
                }
            </Card>
        </div>

    )
};

const mapDispatchToProps = {
    saveFilters,
    resetFilters
};

export default connect(null, mapDispatchToProps)(Filters);
