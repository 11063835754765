import React, { useState } from 'react';
import { makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { ClickAwayListener } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import TabsMenu from "./TabsMenu";
import Locations from "./Locations";
import BusinessFilter from './BusinessFilter';
import FavouritesModule from './FavouritesModule';
import TabPanel from "./TabPanel";
import Customers from './Customers';
import EmeaBusinessUnits from 'components/EMEA/EmeaBusinessUnits';
import EmeaLocations from 'components/EMEA/EmeaLocations';
import EmeaCUSO from 'components/EMEA/EmeaCUSO';
import EmeaFavorites from 'components/EMEA/EmeaFavorites';
import LaoLocations from 'components/LAO/LaoLocations';
import LaoBusinessUnits from 'components/LAO/LaoBusinessUnits';
import LAOFavouritesModule from 'components/LAO/LaoFavouritesModule'; // Added LAO Favorites

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        position: 'absolute',
        right: 0,
        left: 0,
        margin: 0,
        zIndex: 1300,
        width: 'auto',
        height: theme.spacing(65),
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.base,
        '& div::-webkit-scrollbar': {
            height: `${theme.spacing(1.5)}px !important`,
            width: `${theme.spacing(1.5)}px !important`,

        },
        '& div::-webkit-scrollbar-track': {
            border: `5px solid ${theme.palette.secondary.base}`,
            borderRadius: 0,
            background: theme.palette.divider
        },
        '& div::-webkit-scrollbar-thumb': {
            background: theme.palette?.extra?.scrollBar,
            borderRadius: '10px',
            border: `2px solid ${theme.palette.white}`
        },
        [theme.breakpoints.down("sm")]:{
            top:"120px",
            height:"95%"
        }
    },
    left: {
        backgroundColor: theme.palette.menu.base,
        height: theme.spacing(65),
        [theme.breakpoints.down("sm")]:{
            height:"auto",
            backgroundColor: theme.palette.secondary.base,

        }
    },
    rightBgBase: {
        backgroundColor: theme.palette?.secondary.base,
    },
    rightBgDark: {
        backgroundColor: theme.palette?.secondary.secondary,
    },
    right: {
        padding: theme.spacing(3),
        maxHeight: theme.spacing(65),
        overflow: 'auto',
        [theme.breakpoints.down("sm")]:{
            height:"auto",
            padding: theme.spacing(2),
            maxHeight: "100%",
        }
    },
    closeicon: {
        position: "absolute",
        right: theme.spacing(2),
        top: theme.spacing(1.5),
        cursor: "pointer",
        zIndex:10
    },
    hideTabPanel:{
        display:'none'
    }
}));


export const LocationsFilterLayout = (props) => {

    const classes = useStyles();
    const [secondaryTab, setSecondaryTab] = React.useState(0);
    const [primaryTab, setPrimaryTab] = React.useState(0);

    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

    const [search, setSearch] = React.useState({ search: null, type: "ALL", businessUnit: "ALL" });

    const [displayTabs, toggleDisplayTab] = useState({hideTabPanel:true})

    const {
        handleClick,
        open,
        region
    } = props;

    if (!open) return null;

    const handleTabHideShow = (boolean) => {
        if(isMobile){
            toggleDisplayTab({hideTabPanel:boolean})
        }
    }

    const handleLayoutClose = (event) => {
        if (event.target.localName === 'body') {
            return;
        }
        handleClick();              
        handleTabHideShow(true)
    } 

    return (
        <ClickAwayListener onClickAway={(e) => handleLayoutClose(e)} >
            <div className={classes.root} data-testid="locationfilterlayout">
                <span title='closeicon' data-testid='closeicon' className={classes.closeicon} onClick={handleLayoutClose}><CloseIcon /></span>
                <Grid data-testid='filterLocations' container  >
                    <Grid item xs={12} sm={12} md={2} lg={2} className={[
                        classes.left,
                        {
                            [classes.hideTabPanel]:isMobile ? !displayTabs.hideTabPanel : false
                        }]}>
                            <TabsMenu
                                primaryTab={primaryTab}
                                setPrimaryTab={setPrimaryTab}
                                setSecondaryTab={setSecondaryTab}
                                secondaryTab={secondaryTab}
                                handleTabHideShow={handleTabHideShow}
                                region={region}
                            />
                    </Grid>
                    <Grid item xs={12} sm={12} md={10} lg={10} className={[
                        classes.right,
                        {
                            [classes.hideTabPanel]:isMobile ? displayTabs.hideTabPanel : false
                        },
                        classes.rightBgBase
                        ]}>
                            <TabPanel value={secondaryTab} index={0}>
                                {
                                    region === 'EMEA' ?
                                    <EmeaBusinessUnits region={region} /> :
                                    region === 'LAO' ?
                                    <LaoBusinessUnits region={region} /> :
                                    <BusinessFilter handleTabHideShow={handleTabHideShow} />
                                }
                            </TabPanel>
                            <TabPanel value={secondaryTab} index={1}>
                            {
                                region === 'EMEA' ?
                                <EmeaLocations region={region} /> :
                                region === 'LAO' ? 
                                <LaoLocations region={region} /> :
                                <Locations search={search} setSearch={setSearch} handleTabHideShow={handleTabHideShow} />
                            }
                                
                            </TabPanel>
                            <TabPanel value={secondaryTab} index={2}>
                            {
                                region === 'EMEA' ?
                                <EmeaCUSO region={region} /> :
                                <Customers search={search} setSearch={setSearch} handleTabHideShow={handleTabHideShow} />
                            }
                            </TabPanel>
                            {!isMobile && <TabPanel value={secondaryTab} index={3}>
                            {
                                region === 'EMEA' ?
                                <EmeaFavorites region={region} /> :
                                region === 'LAO' ? 
                                <LAOFavouritesModule region={region} /> :
                                <FavouritesModule handleTabHideShow={handleTabHideShow} />
                            }
                            </TabPanel>}
                    </Grid>
                </Grid>
            </div>
        </ClickAwayListener>
    )
};


export default LocationsFilterLayout;
