import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Box, Typography, Grid } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import reduce from "lodash/reduce";
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import Card from "components/common/Card"
import { getChartsService } from "reduxLib/services/getChartsService";
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { useDeepCompareEffect } from 'react-use';
import { kpiShortNames } from "configs/appConstants";
import { isJsonParsable } from 'helpers';
import NextArrowIcon from "../../assets/images/next_arrow_dark.svg";
import PreviousArrowIcon from '../../assets/images/prev_arrow_dark.svg';

const useStyles = makeStyles((theme) => ({
  addMargin: {
    margin: `${theme.spacing(2)}px 0px`
  },
  label: {
    color: theme.palette?.text?.subtitle
  },
  spining: {
    pointerEvents: "none",
    height: '0.9em',
    animation: "App-logo-spin infinite 1s linear !important"
  },
  spin: {
    height: '0.9em',
    cursor: "pointer",
  },
  kpiSection: {
    maxWidth: '95%',
    backgroundColor: theme.palette?.background?.baseLight,
    borderRadius: theme.spacing(.5),
    padding: theme.spacing(2),
    margin: `0px ${theme.spacing(1)}px`,
    display: 'inline-grid',
    justifyItems: 'center',
    textAlign: 'center'
  },
  kpiValue: {
    fontSize: theme.spacing(3),
    color: theme.palette?.text?.secondary
  },
  kpiDesc: {
    width: '90%'
  },
  paddingRight: {
    paddingRight: theme.spacing(1.25)
  },
  pills: {
    width: '100%',
    height: theme.spacing(5),
    cursor: "pointer",
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
    padding: `${theme.spacing(0.75)}px ${theme.spacing(1)}px`,
    borderRadius: theme.spacing(1.25),
    '&:hover': {
      textDecoration: "underline"
    }
  },
  healthyKpi: {
    backgroundColor: theme.palette?.legendColors?.healthy,
    marginTop: theme.spacing(2)
  },
  unhealthyKpi: {
    backgroundColor: theme.palette?.legendColors?.unhealthy
  },
  kpiLegend: {
    padding: theme.spacing(1),
    display: 'inline-flex',
    alignItems: 'center',
    position: 'absolute'
  },
  green: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette?.legendColors?.healthy,
    display: 'inline-block'
  },
  red: {
    height: 10,
    width: 10,
    backgroundColor: theme.palette?.legendColors?.unhealthy,
    display: 'inline-block'
  },
  textContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    width: 'max-content'
  },
  noData: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: 200
  },
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    margin: `0px ${theme.spacing(2)}px`
  }
}));

const OsmKpiCharts = ({ type, setHyperlinkClick }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const subtype = "OSM_FRIEGHT_ORDER_STATUS";
  const kpi_loading = useSelector(({ charts }) => charts?.loaders?.[type]?.[subtype]?.loading);
  const kpi_data = useSelector(({ charts }) => charts?.charts?.[type]?.[subtype]?.data);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [global, setGlobal] = React.useState({});

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 0,
    prevArrow: <img src={PreviousArrowIcon} />,
    nextArrow: <img src={NextArrowIcon} />,
    className: classes.root,
    responsive: [
      {
        breakpoint: 1780,
        settings: {
          slidesToShow: 9,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1710,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 1545,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1360,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1185,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 1010,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 790,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 10000,
        settings: 'unslick'
      }
    ]
  };

  const getOsmKpis= () => {
    const kpiRequestBody = {
      body: {
        region: "NA",
        subtype,
        businessUnits: global?.business,
        sites: global?.sites,
      }, 
      type, 
      subtype
    }
    dispatch(getChartsService(kpiRequestBody));
  }

  useDeepCompareEffect(() => {
    const globalFilters = isJsonParsable(qs.parse(location.search)?.osmglobal) || {};
    setGlobal(globalFilters)
  }, [location.search])

  useDeepCompareEffect(() => {
    if (!isEmpty(global?.sites)) {
      getOsmKpis();
    }
  }, [global?.business, global?.sites]);

  useEffect(() => {
    if(!isEmpty(kpi_data)) {
      setData(kpi_data);
    }
  }, [kpi_data]);

  useEffect(() => {
    const totalCount = reduce(data, function(sum, value) {
      return sum + value?.totalCount;
    }, 0);
    setTotal(totalCount);
  }, [data]);

  const applyHyperlinks = (hyperlink) => {
    const queryParams = qs.parse(location.search);
    const newQueries = {
      ...queryParams,
      hyperlinks: JSON.stringify(hyperlink)
    };

    history.push({
      ...location,
      search: qs.stringify(newQueries, { encode: false })
    });
    setHyperlinkClick(true);
  }

  const KpiTitle = () => {
    return (
      <Grid data-testid='kpiElement'
        container
        justify="space-between"
      >
        <Grid item>
          <Grid data-testid='kpiTitle'>
            <Typography variant="h3"> { t('shipment_freight_order_status') } </Typography>
          </Grid>
          <Typography variant='subtitle1' className={classes.label} data-testid='kpiTotalCount'> { `${t('total')}:` } {total} </Typography>
        </Grid>
        <Grid item className={classes.refreshIcon}>
          <RefreshIcon data-testid="kpiRefresh" className={kpi_loading ? classes.spining : classes.spin} onClick={ getOsmKpis } />
        </Grid>
      </Grid>
    )
  };

  const ChartLegend = () => {
    return (
      <span>
        <div className={classes.kpiLegend} data-testid="osmKpiLegends">
          <div className={classes.green} />
          <div className={classes.textContent}>{t('healthy')}</div>
          <div className={classes.red} />
          <div className={classes.textContent}>{t("unhealthy")}</div>
        </div>
      </span>
    )
  }

  return (
    <div className={classes.addMargin}>
      <Card
        cardtitle={
          <Typography variant="h1" data-testid="osmKpi">
            {t('kpis')}
          </Typography>
        }
      >
        <Grid className={classes.chartGrid}>
          <Card innercard cardtitle={<KpiTitle />} >
            {/* <Grid container justifyContent='space-around'> */}
              { data?.length ? 
                <Slider {...settings}>
                  {data.map(item =>
                    <div className={classes.kpiSection}>
                      <Typography data-testid="kpiValue" className={classes.kpiValue}> {item?.totalCount} </Typography>
                      <Typography data-testid="kpiDescription" className={classes.kpiDesc}> {t(kpiShortNames[item?.state]?.toLowerCase())} </Typography>
                      <Box className={clsx(classes.pills, classes.healthyKpi)} 
                        data-testid="kpi-box-green"
                        onClick={() => applyHyperlinks({
                          osmShipmentExecutionHealth: ["GREEN"],
                          osmShipmentExecutionBucket: [`OSM_${item.state}`]
                      })}>
                        <Typography variant="h1" className={classes.paddingRight}> {item?.greenCount} </Typography>
                        <Typography> { t('healthy') } </Typography>
                      </Box>
                      <Box className={clsx(classes.pills, classes.unhealthyKpi)} 
                        data-testid="kpi-box-red"
                        onClick={() => applyHyperlinks({
                          osmShipmentExecutionHealth: ["RED"],
                          osmShipmentExecutionBucket: [`OSM_${item.state}`]
                      })}>
                        <Typography variant="h1" className={classes.paddingRight}> {item?.redCount} </Typography>
                        <Typography> { t('unhealthy') } </Typography>
                      </Box>
                    </div>
                  )}
                </Slider>
              :
               <div className={classes.noData}>
                  { kpi_loading === false ?
                    <Typography> { t('no_data_found') } </Typography>
                    : <Typography> { t('please_wait_while_the_data_is_loading') } </Typography>
                  }
                </div>
              }
            {/* </Grid> */}
          </Card>
        </Grid>
        <ChartLegend />
      </Card>
    </div>
  );

}

export default OsmKpiCharts;