import React from 'react';
import { makeStyles, useTheme, withStyles, useMediaQuery, IconButton, Grid, TextField, Typography } from '@material-ui/core';
import { connect } from "react-redux";
import FavTickElement from "../common/FavUnfavElement";
import {
    setFavService,
    setUnfavService,
    clearSelected,
    showhideFavourites,
    addItem,
    removeItem
} from "../../reduxLib/services";
import * as _ from "lodash";
import NoResult from "./NoResult";
import KCButton from 'components/common/Button';
import KCSelect from 'components/common/Select';
import clsx from 'clsx';
import { ArrowBack } from '@material-ui/icons';
import { locationfilterlayoutStyles } from "theme"
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';

const CssTextField = withStyles(theme => ({
    root: {
        "& label": {
            color: theme.palette.primary.contrastText
        },
        '& label.Mui-focused': {
            color: theme.palette.primary.contrastText,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: theme.palette.primary.contrastText,
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.contrastText,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.contrastText,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.contrastText,
            },
        },
        '& .MuiOutlinedInput-input': {
            padding: `${theme.spacing(2.25)}px ${theme.spacing(2.5)}px`
        }
    },
}))(TextField);

const useStyles = makeStyles(locationfilterlayoutStyles);

export const InsideFilter = ({ onSearch, ...rest }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [search, setSearch] = React.useState({ search: rest.search || "", type: rest.type });
    const handleChange = (el) => {
        setSearch({ ...search, search: el.target.value });
        onSearch({ ...search, search: el.target.value });
    }

    const handleType = (value) => {
        onSearch({ ...search, type: value })
        setSearch({ ...search, type: value })
    }

    return (
        <Grid data-testid='insideFilter' container spacing={3} className={classes.insidefilter} noValidate autoComplete="off">
            <Grid item>
                <KCSelect value="NA" color="primary" label={t("region")} options={[{ name: "North America", value: "NA" }]} onChange={() => { }} />
            </Grid>
            <Grid item>
                <KCSelect classes={classes.selectOption} color="primary" value={search.type}
                    onChange={handleType} label={t("site_type")}
                    options={[
                        { name: t('all_dc_and_mill'), value: "ALL" },
                        { name: t('dc'), value: "DC" },
                        { name: t('mill'), value: "MILL" },
                    ]} />
            </Grid>
            <Grid item>
                <CssTextField input="" id="outlined-basic" label={t("location_search")} value={search.search} onChange={handleChange} variant="outlined" />
            </Grid>

        </Grid>
    )
}

const getValueFromKey = (data = [], key = "siteNum") => {
    const values = [];
    data.map(d => values.push(d[key]));
    return values;
}

export const FilterLocations = ({ search, setSearch,handleTabHideShow, ...props }) => {

    const classes = useStyles();
    const { t } = useTranslation();
    const {
        favorites,
        items,
        showhideFavourites,
        clearSelected,
        businessUnitsLocal,
    } = props;

    const [favElementKeys, setFavElementKeys] = React.useState([]);
    const [locations, setLocations] = React.useState(props.locations);

    const favids = favorites.map(d => d.siteNum);
    let locationsFiltered = locations.map(d => ({ ...d, isFav: favids.includes(d.siteNum) }));

    React.useEffect(() => {
        const values = getValueFromKey(favorites, "siteNum");
        setFavElementKeys(values);
    }, [favorites]);

    const searchKey = (key, d) => {
        key = key.toLowerCase();
        return (d.shortName.toLowerCase().indexOf(key) > -1)
            || (d.siteNum.toLowerCase().indexOf(key) > -1)
            || (d.alias?.toLowerCase().indexOf(key) > -1)
    }

    locationsFiltered = locationsFiltered.filter((d) => {
        if (search.search && search.type !== "ALL") {
            return searchKey(search.search, d) && d.type === search.type
        } else if (search.search) {
            return searchKey(search.search, d)
        } else if (search.type !== "ALL") {
            return d.type === search.type
        } else return true;
    });

    React.useEffect(() => {
        const filtered = props.locations.filter(d => {
            if (businessUnitsLocal.length === 2 || businessUnitsLocal.length === 0) {
                return true;
            } else {
                return d.businessUnits?.includes(businessUnitsLocal[0]);
            }
        });
        setLocations(filtered);
    }, [businessUnitsLocal, props.locations])

    const noresults = search.search && locationsFiltered.length === 0;


    const clearSelection = ({ items: items1 }) => {
        const data = [...locationsFiltered, ...items1];
        const dup = _.reduce(_.countBy(data, 'siteNum'), (finalArray, count, siteNum) => count > 1 ? finalArray.concat(siteNum) : finalArray, [])
        const res = _.uniqBy(data.filter(d => dup.includes(d.siteNum)), "siteNum");

        if (_.find(res, { isFav: true })) {
            showhideFavourites(false);
        }
        clearSelected(res);
    }

    const disableClearItems = (locations1, items1) => {
        if (items1.length === 0) return true;
        const data = [...locations1, ...items1];
        const dup = _.reduce(_.countBy(data, 'siteNum'), (finalArray, count, siteNum) => count > 1 ? finalArray.concat(siteNum) : finalArray, [])
        return dup.length === 0;
    }

    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))
    return (
        <div className={classes.root} data-testid='filterLocations'>
            {isMobile && <IconButton data-testid="mobilebackbutton" onClick={()=>handleTabHideShow(true)}>
                    <ArrowBack/><Typography>{t('location')}</Typography>
                </IconButton>
            }
            <InsideFilter
                onSearch={setSearch}
                search={search.search}
                type={search.type}
            />
            {
                noresults ?
                    <NoResult value={search.search} searchType={"Locations"} /> : null
            }
            <Grid container alignContent="flex-start" className={clsx(classes.overflowLocations, noresults ? classes.center : {})}>
                {
                    locationsFiltered.map(d => {
                        return <FavTickElement key={d.siteNum}
                            favElements={favElementKeys} data={d}
                            unCheckClick={removeItem}
                            checkClick={addItem}
                            favClick={setFavService} 
                            unfavClick={setUnfavService} 
                            tick={find(items, { siteNum: d.siteNum })}
                            tooltipTitle={`${d?.alias || ""} ${d?.siteNum}`}
                            fav={find(favorites, { siteNum: d.siteNum })}
                            />
                    })
                }
            </Grid>
            <KCButton
                className={classes.actionButton} variant="outlined"
                onClick={() => clearSelection({ locations: locationsFiltered, items })}
                disabled={disableClearItems(locationsFiltered, items)} >
                {t('clear_selections')}
            </KCButton>
        </div>
    )
};

const mapStatetoProps = (state) => {
    return {
        loading: state.sites.loading,
        items: state.items.items,
        favsLoading: state.favorites.loading,
        locations: state.sites.locations || [],
        favorites: state.favorites.favorites || [],
        markLoading: state.favorites.mark_loading,
        businessUnits: _.get(state, "favorites.businessUnits", []),
        businessUnitsLocal: _.get(state, "favorites.tempBusinessUnit", []),
    }
}
const mapDispatchToProps = {
    clearSelected,
    showhideFavourites
};
export default connect(mapStatetoProps, mapDispatchToProps)(FilterLocations);
