import { colors } from '@material-ui/core';

const white = '#FFF'
const black = colors.grey[900];

const coloursDark = {
  background: '#131834',//background //filterbar

  primaryBase: '#1C2340', //bg4 //primaryBase //topbar
  primaryHover: "#303751",
  primarySelected: "#2E354F",
  primaryFocus: "#373D57",
  primaryPressed: "#333953",
  primaryDragged: "#3B4472", //add box shadow from figma when using

  secondaryBase: "#465083", //FormLightBase
  secondaryDark: "#303862", //FormDarkBase
  secondaryHover: "#4D5788", //FormLightHover
  secondarySelected: "#57608E", 
  secondaryFocus:"#5C6592",  //FormLightActive
  secondaryPressed: "#555E8D", //Secondary Dragged
  
  menuBase: "#5C67A4",
  
  cardBase: "#272C4F",
  cardHover: "#303651",
  cardFocus: "#414760",

  formDarkHover : "#384068",
  formDarkActive: "#495075",

  greyMedium : "#939597",
  greyLight: "#CACACA",
  poppyWhiskers: "#CCD7DD",

  healthy: "#56B1FA",
  unhealthy: "#FF2972",
  risk: "#FFC654", //Need attention

  consumer: "#6C34CB",
  professional: "#AB87D2",
  STO: "#FFFADE",
  
  healthyGreen: "#00FF81",
  accent: '#0091EA', //Accent
  lightCyanBlue:'#7EC7FC',
  midnightExpress:'#1c2340',
  silver: '#C4C4C4',
  
  malibu80:'#97dffc', //Also linkDefault from design
  ultramarine: '#10059F', //Link Hover
  breathtaking: '#58628f',
  periwinkleBlue: '#8CA0FF',
  evilEye: '#1306C1',
  tropicalNightBlue: "#272E4A",
  
  //ApprovedLegend colors
  malibu100:'#56b1FA', //Also linkActive //Healthy
  radicalRed:'#FA1A66',
  goldTainoi:'#FFC654',

  //Pie and Doughnut Colors
  frenchLilac:'#78578A',
  purpleMountain:'#9973B5',
  floralLavender:'#BA8FE0',
  lilac:'#CCA2C6',
  desertSand:'#E7C1B1',
  unbleachedSilk:"#EFEDBD",
  champagnePink:"#F3DACE",
  mistyRose:"#F5E2DF",
  cultured:"#F6F4F4",

  iron:'#D5D3D7', // From color palette 4
  chambray: '#384680',//kc_dark_border // ChipDefaultBorder
  democrat: '#00AEEF',
  darkToneInk: '#121212',
  
  //Error Message Colors
  coralRed100: '#b71c1c',
  coralRed60: '#e53935',
  coralRed40: '#ef5350',

   //Chart Colors New
  criticalRed: '#FF0000',
  pink: '#FF4081',

  greyBlue: '#7480BF',
  linkBlue: '#1a0dab',
  lightPurpleblue: "#F0FAFF",
  nightLife: '#28436D',
  paleBlue: '#EDF6F9',
  poloBlue: '#8993CD',
  lightGrey: 'rgba(0, 0, 0, 0.08)'
};

const colorPanelEMEA = [
  "#9375B1", "#B391DB", "#C5A4C4", "#E1C2B3", "#EFDBD0","#EFEDC2"
];

const set1 = ["#6EE7B7", "#FCD34D", "#ED9999", "#5C21B8", "#4700B3"];
const set2 = ["#4700B3", "#003E9C", "#2B7FFF", "#A66BFF", "#96C0FF"];
const set3 = ["#92400E", "#B45309", "#D97706", "#F59E0B", "#FBBF24"];
const set4 = ["#064E3B", "#FCD34D", "#0091EA"];
const set5 = ["#9375B1", "#F59E0B", "#064E3B", "#0091EA", "#EFDBD0"];
const set6 = ["#064E3B", "#F59E0B", "#0091EA", "#EFDBD0", "#9375B1"];
const set7 = ["#6C34CB"];
const set8 = ["#AB87D2"];
const set9 = ["#2B7FFF"];
const set10 = ["#56B1FA", "#9375B1", "#FFC654"]
const set11 = ["#A45E52", "#E8B0A6", "#377E87", "#0091EA", "#1C2340"];
const set12 = ['#006557'];
const treemapColors = ["#52B4C1", "#525C6B", "#A8ADB5", "#377E87", "#DA7D6D", 
"#A9E0E6", "#68AFE2", "#C8C47C", "#868D98", "#78C9B5", "#98CC89", "#D4D6DA", 
"#8DD6E5", "#387254", "#D8D486", "#C1BCFD", "#FCD34D", "#10B981", "#0C2041", "#FCD34D"];

const dark = {
  type:'dark',
  colorPanelEMEA,
  set1,
  set2,
  set3,
  set4,
  set5,
  set6,
  set7,
  set8,
  set9,
  set10,
  set11,
  set12,
  treemapColors,
  primary: {
    contrastText: white,
    main: white,
    base:coloursDark.primaryBase,
    hover:coloursDark.secondarySelected,
    focus:coloursDark.primaryFocus,
    active: coloursDark.primaryHover,
    dragged: coloursDark.menuBase,
    pressed: coloursDark.formDarkHover,
    primaryBase: white
  },
  secondary: {
    dark: coloursDark.secondaryDark,
    base:coloursDark.secondaryBase,
    accent:coloursDark.accent,
    contrastText: white,
    secondary: coloursDark.background,
    main:coloursDark.accent,
    hover:coloursDark.secondaryHover,
    lightCyanBlue:coloursDark.lightCyanBlue,
    midnightExpress:coloursDark.midnightExpress,
    silver:coloursDark.silver,
    border: coloursDark.greyBlue,
    focus: coloursDark.secondaryFocus,
    pressed: coloursDark.darkToneInk
  },
  error: {
    contrastText: white,
    dark: coloursDark.coralRed100,
    main: coloursDark.coralRed60,
    light: coloursDark.coralRed40
  },
  text: {
    primary: white,
    secondary: coloursDark.greyMedium,
    link: coloursDark.malibu80,
    underline:coloursDark.cardBase,
    caption: coloursDark.secondaryBase,
    main: coloursDark.primaryBase,
    tertiarySubtitle: coloursDark.nightLife
  },
  form: {
    baseDark:coloursDark.secondaryDark,
    baseLight:coloursDark.secondaryBase
  },
  menu:{
    base:coloursDark.menuBase,
    active: coloursDark.primaryHover
  },
  card:{
    base:coloursDark.cardBase,
    default: coloursDark.primaryBase,
    border: black
  },
  chip:{
    borderActive:white,
    borderDefault:coloursDark.chambray,
    backgroundActive:coloursDark.menuBase,
    backgroundDefault:coloursDark.secondaryBase,
    tableChips: coloursDark.secondaryDark
  },
  link:{
    default:coloursDark.secondaryDark,
    hover:coloursDark.ultramarine,
    linkDefault: coloursDark.linkBlue,
    linkPrimary: coloursDark.evilEye
  },
  icon: coloursDark.malibu80,
  background: {
    default: coloursDark.background,
    paper: white,
    selected: coloursDark.secondaryDark,
    filters: coloursDark.secondaryDark,
    indicator: coloursDark.democrat,
    drawer: coloursDark.formDarkHover,
    secondaryBase: coloursDark.poloBlue,
    selectedView: coloursDark.lightGrey
  },
  divider: coloursDark.greyMedium,  
  topbar: coloursDark.primaryBase,
  filterbar: coloursDark.background,
  black,
  white,
  chartColors: {
    healthy:coloursDark.healthy,
    risk:coloursDark.risk,
    unhealthy:coloursDark.unhealthy
  },
  legendColors: {
    healthy:coloursDark.healthy,
    risk:coloursDark.risk,
    unhealthy:coloursDark.unhealthy,
    healthyGreen: coloursDark.healthyGreen,
    criticalRed: coloursDark.criticalRed,
    pink: coloursDark.pink,
    greyLight: coloursDark.greyLight
  },
  chartColorsMulti:   [coloursDark.frenchLilac, coloursDark.purpleMountain, coloursDark.floralLavender, coloursDark.lilac, coloursDark.desertSand, coloursDark.unbleachedSilk, coloursDark.champagnePink, coloursDark.mistyRose, coloursDark.cultured ],
  border: coloursDark.chambray,
  extra:{
    iron:coloursDark.iron,
    iconInactive: coloursDark.breathtaking,
    filterBorder: coloursDark.periwinkleBlue,
    filterTitle: coloursDark.secondaryBase,
    filterActions: coloursDark.midnightExpress,
    expandFilter: coloursDark.secondaryDark,
    scrollBar: coloursDark.poppyWhiskers,
    mobileProfile: coloursDark.secondaryBase
  },
  guardrailColors: {
    consumer:coloursDark.consumer,
    professional:coloursDark.professional,
    STO:coloursDark.STO
  },
  table: {
    scrollColor: coloursDark.greyMedium,
    scrollBorder: white,
    primaryBase: coloursDark.tropicalNightBlue
  },
  tooltip: {
    lightPurpleblue: coloursDark.lightPurpleblue,
    paleBlue: coloursDark.paleBlue
  },
};

const colorsLight = {
  alucardsNight: '#050050',
  insigniaWhite: '#EBF1FB',
  berwickBerry: '#7285A6',
  aloneInTheDark: '#00006C',
  glass: '#BED2F3',
  brilliantWhite: '#E6F2FF',
  nightLife: '#28436D',
  blueMarguerite: "#10069F",
  lead: "#212121",
  irisBloom: '#5A659C',
  aliceBlue: '#F2F8FF',
  sweetEmily: '#C9CFE2',
  ultramarineBlue: '#3652FF',
  puffyCloud: '#D1DFF7',
  magnolia: '#FAF8FF',
  estateBlue: '#25395A',

  background: '#F2F2F2',
  titanWhite: '#EDEEFF',
  whiteSolid: '#F3F6F9',
  stoicWhite: "#E0E5FD",
  whiteLotion: '#FAFAFA',
  richBlue: '#5C67A4',
  midnightSea: "#555E8D",
  chambray: '#465083',
  paleCornflowerBlue: '#BCC4F2',
  frostedIris: "#AFB5DA",
  moodyBlue: '#7A85C6',
  glaucous: '#6B75B1',
  dodgerBlue: '#0091EA',
  groundWater: '#10059F',
  ravenNight: '#384068',
  larkspurBud: '#B6BFF0',
  purpleHeart: '#613DC1',
  corsicanPurple: '#7982B0',
  kickstartPurple: 'rgba(110, 126, 208, 0.07)',
  ghostWhite: '#F4F6FF',
  quartz: '#E7E6F8',
  blueWhale: '#1C2340',
  deepKoamaru: '#303862',
  cloudBreak: '#F5F3FF',
  paleGrey: '#FCFCFF',
  spanishGrey: '#939597',
  lightShadow: 'rgba(0, 0, 0, 0.26)',
  darkShadow: 'rgba(0, 0, 0, 0.50)',
  poloBlue: '#8993CD',
  carteBlanche: '#E9ECFF',
  greenEpiphany: '#80FFBA',
  bananaBread: '#FFD073',
  orangeAura: '#FF9482',
  red: '#FF0000',
  lightPurpleblue: "#F0FAFF",
  paleBlue: '#EDF6F9',
  firebrick: "#BC2121",
  blueBonnet: "#1707E2"
}

const light = {
  type: 'light',
  colorPanelEMEA,
  set1,
  set2,
  set3,
  set4,
  set5,
  set6,
  set7,
  set8,
  set9,
  set10,
  set11,
  set12,
  white,
  black,
  treemapColors,
  background: {
    default: white,
    primaryBase: colorsLight.irisBloom,
    secondaryBase: colorsLight.poloBlue,
    selected: colorsLight.glass,
    baseLight: colorsLight.kickstartPurple,
    filters: colorsLight.ghostWhite,
    tertiaryBase: colorsLight.stoicWhite,
    quaternaryBase: colorsLight.whiteLotion,
    indicator: coloursDark.democrat,
    drawer: colorsLight.insigniaWhite
  },
  primary: {
    main: colorsLight.brilliantWhite,
    base: colorsLight.paleGrey,
    primaryBase: colorsLight.cloudBreak,
    secondaryBase: colorsLight.magnolia,
    contrastText: colorsLight.chambray,
    hover: colorsLight.quartz,
    active: colorsLight.frostedIris,
    dragged: coloursDark.primaryDragged,
    pressed: colorsLight.berwickBerry,
    button: colorsLight.blueMarguerite
  },
  secondary: {
    main: colorsLight.quartz,
    base: colorsLight.ghostWhite,
    secondary: colorsLight.paleCornflowerBlue,
    accent: colorsLight.purpleHeart,
    focus: colorsLight.titanWhite,
    border: colorsLight.chambray,
    dark: colorsLight.deepKoamaru,
    pressed: colorsLight.aloneInTheDark,
    button: colorsLight.puffyCloud
  },
  text: {
    main: white,
    primary: colorsLight.alucardsNight,
    secondary: colorsLight.deepKoamaru,
    link: colorsLight.nightLife,
    caption: colorsLight.chambray,
    error: colorsLight.red,
    subtitle: colorsLight.richBlue,
    secondarySubtitle: colorsLight.midnightSea,
    tertiarySubtitle: colorsLight.lead,
    unhealthy: colorsLight.firebrick,
    healthy: colorsLight.aloneInTheDark
  },
  chartColors: {
    healthy:coloursDark.healthy,
    risk:coloursDark.risk,
    unhealthy:coloursDark.unhealthy
  },
  chartColorsMulti:   [coloursDark.frenchLilac, coloursDark.purpleMountain, coloursDark.floralLavender, coloursDark.lilac, coloursDark.desertSand, coloursDark.unbleachedSilk, coloursDark.champagnePink, coloursDark.mistyRose, coloursDark.cultured ],
  border: colorsLight.alucardsNight,
  link: {
    default: colorsLight.glass,
    active: colorsLight.purpleHeart,
    hover: colorsLight.larkspurBud,
    primary: colorsLight.ultramarineBlue
  },
  tooltip: {
    main: colorsLight.ravenNight,
    base: colorsLight.dodgerBlue,
    lightPurpleblue: colorsLight.lightPurpleblue,
    paleBlue: colorsLight.paleBlue
  },
  topbar: colorsLight.chambray,
  legendColors: {
    healthy: colorsLight.greenEpiphany,
    risk: colorsLight.bananaBread,
    unhealthy: colorsLight.orangeAura,
    healthyGreen: coloursDark.healthyGreen,
    criticalRed: coloursDark.criticalRed,
    pink: coloursDark.pink,
    greyLight: colorsLight.spanishGrey
  },
  menu:{
    base: colorsLight.glaucous,
    active: coloursDark.primaryHover
  },
  card:{
    base: white,
    default: colorsLight.brilliantWhite,
    header: colorsLight.whiteSolid,
    border: colorsLight.sweetEmily,
    miniCard: colorsLight.whiteSolid
  },
  table: {
    scrollColor: white,
    scrollBorder: colorsLight.spanishGrey,
    primaryBase: colorsLight.aliceBlue,
    secondaryBase: colorsLight.lightPurpleblue,
    footer: colorsLight.estateBlue,
    secondaryHeader: colorsLight.blueWhale
  },
  extra:{
    iron: colorsLight.chambray,
    iconInactive: white,
    info: colorsLight.groundWater,
    filterBorder: colorsLight.chambray,
    filterTitle: colorsLight.chambray,
    filterActions: colorsLight.quartz,
    expandFilter: colorsLight.ghostWhite,
    scrollBar: colorsLight.lead,
    mainShadow: colorsLight.lightShadow,
    primaryShadow: colorsLight.darkShadow,
    mobileProfile: colorsLight.midnightSea,
    infoHighlight: colorsLight.blueBonnet
  },
  chip:{
    backgroundDefault: white,
    borderActive: colorsLight.moodyBlue,
    tableChips: white
  },
  form: {
    baseLight: white
  }
}

export { dark, light }