import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles, Tabs, Tab, Divider, ListItem, ListItemText, ListItemIcon, useTheme, useMediaQuery } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TabPanel from "./TabPanel";
import FavoritesModule from "./FavouritesModule"
import {FavBlock} from "./FavouritesModule"

const CustomTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.white}`,
  },
  indicator: {
    backgroundColor: theme.palette?.background?.indicator,
    height: theme.spacing(.5),
    maxWidth: '50%'
  },
}))(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: '50%',
    fontWeight: theme.typography.fontWeightRegular,
    backgroundColor: theme.palette?.primary?.dragged,
    color: theme.palette?.white,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette?.menu?.active,
      fontWeight: theme.typography.fontWeightMedium,
      minWidth: '50%'
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />);


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  listitem: {
    height: theme.spacing(8),
    color: theme.palette?.white,
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette?.secondary?.dark
    }
  },
  icon: {
    padding: 0,
    margin: 0,
    minWidth: 0,
    color: theme.palette?.white,
  }
}));

const TabsMenu = ({ setSecondaryTab, secondaryTab, primaryTab, setPrimaryTab, handleTabHideShow, region }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"));

  const primaryTabEvent = (e, tabindex) => {
    setPrimaryTab(tabindex);
    if (tabindex === 1) {
      setSecondaryTab(3);
    } else {
      setSecondaryTab(0);
    }
  }

  const tabsList = [t('business'), t('location'), t('sales_office_customer')] 
  if (region === 'LAO') {
    tabsList.pop();
  }

  return (
    <div className={classes.root} data-testid="tabsmenu">
      <CustomTabs value={primaryTab} onChange={primaryTabEvent}  >
        <CustomTab label={t('menu')} title="Menu" />
        <CustomTab label={t('favourites')} title="Favourites" />
      </CustomTabs>
      <TabPanel value={primaryTab} index={0}>
        {tabsList.map((text, index) => (
          <>
            <ListItem
              name="listitem"
              data-testid={`${text}_tab`}
              onClick={(event) => {                
                setSecondaryTab(index)
                handleTabHideShow(false)
              }}
              button key={text} selected={secondaryTab === index} className={classes.listitem} >
              <ListItemText primary={text} />
              <ListItemIcon className={classes.icon}>
                <ChevronRightIcon />
              </ListItemIcon>
            </ListItem>
            <Divider />
          </>
        ))}
      </TabPanel>
      <TabPanel value={primaryTab} index={1}>
        { isMobile ? 
          <FavoritesModule NoFavorites={FavBlock} />:
          <FavBlock />
        }
      </TabPanel>
    </div>
  );
}


export default TabsMenu;