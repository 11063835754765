import React,{useState, useEffect} from "react"
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles, Typography, Box, useTheme, useMediaQuery } from "@material-ui/core";
import { locationfilterlayoutStyles, KCReactTabs } from "theme"
import FavTickElement from "../common/FavUnfavElement";
import { useSelector, useDispatch } from "react-redux";
import KCButton from 'components/common/Button';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SalesGroupSearchLisiting from "./SalesGroupSearchLisiting";
import SearchBar from "components/common/SearchBar"
import {
    addItemCuso,
    removeItemCuso,
    clearAllCuso, 
    setFavCUSOService,
    addItemCusoSF,
    removeItemCusoSF
} from "../../reduxLib/services"
import { find, isEmpty, concat, isEqual } from "lodash"
import CustomersChipComponent from "./CustomersChipComponent";
import Drilldown from "../../assets/images/drilldown.png";
import { TooltipTitle } from "./Customers";
import NoResult from "./NoResult";
import {usePrevious} from 'react-use';

const useStyles = makeStyles(locationfilterlayoutStyles);

const useStyles1 = makeStyles(KCReactTabs);

export default (props) => {

    const { t } = useTranslation();
    const {listingFilters, selfalert} = props

    let addCuso = addItemCuso;
    let removeCuso = removeItemCuso;

    if(selfalert) {
        addCuso = addItemCusoSF;
        removeCuso = removeItemCusoSF;
    }

    const classes = useStyles()
    const reactTabs = useStyles1()
    const [search, setSearch] = React.useState("");
    const prevListingFilters = usePrevious(listingFilters)
    const { cuso } = useSelector(({selfalerting, items}) =>  {
        if(selfalert) return selfalerting;
        else return items;
    });    
    const salesOfficesList = useSelector(({sites}) => sites.salesOffice).filter((salesOffice)=>search!=='' ? salesOffice.salesOffice.toLowerCase().indexOf(search.toLowerCase()) > -1: true)
    const filterCheckedSalesOrg = listingFilters.saleOrgsCheckboxArray.filter(({checked}) => checked )
    
    const { favCuso } = useSelector(({favorites}) =>  favorites)
    const dispatch = useDispatch()
    const isMobile = useMediaQuery(useTheme().breakpoints.down("sm"))


    const [salesGroups, setSalesGroups ] = useState({open:false,list:[]})

    const [tabIndex, setTabIndex] = useState(0)
    const handleSalesOfficeLink = ({open,data={}}) => {
        setSalesGroups({
            open,
            list:data?.salesGroup,
            reducerObject:data?.reducerObject,
            fromState:data?.fromState,
            fromFavState:data?.fromFavState
        })
        //  if(tabIndex !== "" && filterCheckedSalesOrg.map(({value}) => value).includes(tabIndex)){
        //     setTabIndex(tabIndex)
        // }
    }

    const [favElementKeys, setFavElementKeys] = useState([]);

    const getValue = (data = []) => {
        const values = [];
        data.map(d => values.push(d[0]));
        return values;
    }

    useEffect(() => {
        const values = getValue(favCuso.FAV_SALES_OFFICE);
        setFavElementKeys(values);
    }, [favCuso]);

    useEffect(()=>{
        setSalesGroups({
            ...salesGroups,
            fromState: find(cuso.SALES_OFFICE, (SO) => 
                ( SO.salesOffice === salesGroups.reducerObject?.salesOffice && SO.salesOrg === salesGroups.reducerObject?.salesOrg &&  SO.distributionChannel === salesGroups.reducerObject?.distributionChannel )),
            fromFavState: find(favCuso.FAV_SALES_OFFICE, (SO) => 
                ( SO.salesOffice === salesGroups.reducerObject?.salesOffice && SO.salesOrg === salesGroups.reducerObject?.salesOrg &&  SO.distributionChannel === salesGroups.reducerObject?.distributionChannel ))
        }) 
    },[cuso, favCuso])

    useEffect(()=>{
        if(!isEqual(listingFilters, prevListingFilters))
        setTabIndex(0)
    },[listingFilters])

    return(
        <>
        {!salesGroups.open ?
            <>
            <Grid container spacing={3}>
                <Grid item xs={4} >
                    <Box mb={2} >
                        {!isMobile && <Typography>{t('search_text')}</Typography>}
                        <SearchBar searchPhrase={search} handleChange={(value)=> setSearch(value)} placeholder={"ex: CS01"} handleSubmit={()=>{}} />
                    </Box>
                </Grid>
                {!isEmpty([...cuso.CUST,...cuso.SALES_OFFICE]) && <Grid item xs={8}>
                    <CustomersChipComponent action={removeItemCuso} chipsObjectArray={[...cuso.CUST,...cuso.SALES_OFFICE]} selfalert={selfalert} />  
                </Grid>}
            </Grid>
            <Tabs data-testid='SOListing' className={reactTabs.tabs} selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)} >
                {isEmpty(filterCheckedSalesOrg) ? <NoResult value={search} searchType={t("sales_office")} /> : 
                    <TabList >
                    { filterCheckedSalesOrg.map(({value}) => (
                        <Tab data-testId={`tab_${value}`} >{!isMobile && t("sales_org")} {value}</Tab>
                    ))}
                    </TabList>}

                { !isEmpty(filterCheckedSalesOrg) && filterCheckedSalesOrg.map(({value}) => (
                    <TabPanel>
                        {isEmpty(salesOfficesList.filter((salesOffice) => {
                                const SOfromDC = salesOffice.salesOrgList.filter(({distributionChannel, salesOrg}) => distributionChannel===listingFilters.selectedDC && salesOrg === value).length > 0
                                return SOfromDC 
                            })) ? <NoResult value={search} searchType={t("sales_office")} /> : <Grid xs={12}><Box ><Typography variant='h6' data-testid="SOListTitle" >{`${t('list_of_sales_offices_for_sales_org')} ${value}`}</Typography></Box><Box mb={2} ><Typography variant='h6'>{t("access_sales_group_by_clicking_on_the_sales_office")}</Typography></Box></Grid>

                        }
                        <Grid container alignContent="flex-start" className={{
                            [classes.overflowLocations]:true, 
                            [classes.height] : true
                        }}>
                        {
                            salesOfficesList.filter((salesOffice) => {
                                const SOfromDC = salesOffice.salesOrgList.filter(({distributionChannel, salesOrg}) => distributionChannel===listingFilters.selectedDC && salesOrg === value).length > 0
                                return SOfromDC 
                            }).map(SO => {
                                return <FavTickElement 
                                    selfalert={selfalert}
                                    key={SO.salesOffice}
                                    favElements={favElementKeys} 
                                    tooltipTitle={find(cuso.SALES_OFFICE, { salesOffice:SO.salesOffice, salesOrg:value, distributionChannel:listingFilters.selectedDC === '80' ? '90': '80'}) ? <TooltipTitle dc={listingFilters.selectedDC} /> : undefined}
                                    data={{
                                        shortName:`${SO.salesOffice}`,
                                        salesGroup:SO.salesGroupList,
                                        fromState:find(cuso.SALES_OFFICE, (fs) => fs.salesOffice === SO.salesOffice && fs.salesOrg === value) || {},
                                        fromFavState:find(favCuso.FAV_SALES_OFFICE, (fs) => fs.salesOffice === SO.salesOffice && fs.salesOrg === value) || {},
                                        reducerObject:{
                                            salesOffice:`${SO.salesOffice}`,
                                            selectionType:"SALES_OFFICE",
                                            salesOrg:value,
                                            distributionChannel:listingFilters.selectedDC,
                                        },
                                        state: concat(favCuso.FAV_CUST, favCuso.FAV_SALES_OFFICE)
                                    }}
                                    icon={(find(cuso.SALES_OFFICE, { salesOffice:SO.salesOffice, salesOrg:value, distributionChannel:listingFilters.selectedDC})?.salesGroup || 
                                        find(favCuso.FAV_SALES_OFFICE, { salesOffice:SO.salesOffice, salesOrg:value, distributionChannel:listingFilters.selectedDC})?.salesGroup || 
                                        []).length > 0 ? Drilldown : null}
                                    handleClick={handleSalesOfficeLink} 
                                    unCheckClick={removeCuso}
                                    checkClick={addCuso}
                                    favClick={setFavCUSOService} 
                                    unfavClick={setFavCUSOService}
                                    tick={find(cuso.SALES_OFFICE, { salesOffice:SO.salesOffice, salesOrg:value, distributionChannel:listingFilters.selectedDC})} 
                                    fav={find(favCuso.FAV_SALES_OFFICE, { salesOffice: SO.salesOffice, salesOrg: value, distributionChannel: listingFilters.selectedDC })} />
                            })
                        }
                        </Grid>
                    </TabPanel>
                ))}
            </Tabs>
            <KCButton data-testid='SOButton'
                className={[classes.actionButton, classes.actionButtonCUSO]} variant="outlined"
                onClick={() => dispatch(clearAllCuso())}
                disabled={isEmpty([...cuso.CUST, ...cuso.SALES_OFFICE])} 
                >
                {t('clear_selections')}
            </KCButton></>
            :<SalesGroupSearchLisiting handleSalesOfficeLink={handleSalesOfficeLink} salesGroups={salesGroups} selfalert={selfalert} />}
        </>
    )
}