import React, { useEffect } from 'react';
import { useThemeContext } from 'context/ThemeContext';
import { withOktaAuth } from '@okta/okta-react';
import { useLocation, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Appbar from './AppBar';
import EmeaMainLayout from './EMEA/EmeaMainLayout';
import DownloadManager from 'components/common/Table/DownloadManager';
import LaoMainLayout from 'theme/layouts/LAO/LaoMainLayout';

const RegionRootLayout = ({ ...props }) => {
  const { light, dark, setTheme } = useThemeContext();  
  const location = useLocation();
  const history = useHistory();
  const { authState } = props;

  const regionFromUrl = location?.pathname.split('/')[2];

  React.useEffect(() => {
    if (!authState?.isAuthenticated) {
      history.push(
        `/redirect?page=${regionFromUrl}&path=${encodeURIComponent(
          location?.pathname
        )}&search=${location.search}`
      );
    }
  }, [authState]);

  useEffect(() => {
    regionFromUrl === 'EMEA' ? setTheme(light) : setTheme(dark);
    return () => {
      setTheme(dark);
    };
  }, []);

  return (
  <div data-testid="regionRootLayout">
    <Box>      
      <Appbar region={regionFromUrl} {...props} />
      <DownloadManager />
      {regionFromUrl === 'EMEA' && (
        <EmeaMainLayout component={props.component} region={regionFromUrl} />
      )}
      {regionFromUrl === 'LAO' && (
        <LaoMainLayout component={props.component} region={regionFromUrl} />
      )}      
    </Box>
  </div>
  );
};

export default withOktaAuth(RegionRootLayout);
