const laoImportOrderDetailsFilters = {
  searchStringList: { // PO numbers, Invoice Number, Load Number and Delivery Number
    type: "text",
    stringToArray: true,
    name: "search",
    data: null,
    region: 'lao'
  },
  invoiceDateRange: { // Invoice Date
    type: "date",
    name: "invoicing_date",
    shortName: 'Invoice Date',
    data: null
  },
  importEtaRange: { // KC ETA
    type: "date",
    name: "import_eta_date",
    shortName: 'KC ETA',
    data: null
  },
  vendorNumberList: { // Vendor Number
    type: "text",
    name: "vendor_number",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  vendorNameList: { // Vendor Name
    type: "text",
    name: "vendor_name",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  orderDestinationIdList: { // Customer Code/Plant 
    type: "text",
    name: "customer_code_plant",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  orderDestinationNameList: { // Customer Name
    type: "text",
    name: "customer_name",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  importInternationalShipperList: { // International Shipper
    type: "text",
    name: "international_shipper",
    stringToArray: false, // Single Search
    data: null,
    region: 'lao'
  },
  importContainerIdList: { // Container ID 
    type: "text",
    name: "container_id",
    stringToArray: false, // Single Search
    data: null,
    region: 'lao'
  },
  materialNumList: { // SKU (Material)
    type: "text",
    name: "sku_id",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  itemDescList: { // Material Description
    type: "text",
    name: "material_description",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  materialTypeList: { // Material Type
    type: "text",
    name: "material_type",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  importOrderOriginCountryList: { // Origin Country
    type: "text",
    name: "origin_country",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  importOrderDestinationCountryList: { // Destination Country
    type: "text",
    name: "destination_country",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  // importBillOfLadingList: { // Bill of Lading
  //   type: "text",
  //   name: "bill_lading",
  //   stringToArray: true,
  //   data: null,
  //   region: 'lao'
  // },
  importShipmentModeList: { // Shipment Mode
    type: "text",
    name: "shipment_mode",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  importPostShipmentStatusList: { // Post Shipment Status
    type: "text",
    name: "import_post_shipment_status",
    stringToArray: false,   // Single Search
    data: null,
    region: 'lao'
  },
  importEtdRange: { // KC ETD
    type: "date",
    name: "import_etd_date",
    shortName: 'KC ETD',
    data: null
  },
  importIDDateRange: { // ID Date
    type: "date",
    name: "import_id_date",
    shortName: 'ID Date',
    data: null
  },
  importCustomsChannelList: { // Customs Channel
    type: "text",
    name: "import_customs_channel",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  importFreePeriodEndDateRange: { // Free Period End
    type: "date",
    name: "import_free_period_end_date",
    shortName: 'Free Period End',
    data: null
  },
  importReqDateContAtPlantRange: { // Req Date Cont At Plant
    type: "date",
    name: "import_req_date_cont_at_plant",
    shortName: 'Req Date Cont At Plant',
    data: null
  },
  importDateContAtPlantRange: { // Date Cont At Plant
    type: "date",
    name: "import_date_cont_at_plant",
    shortName: 'Date Cont At Plant',
    data: null
  },
  deliveryCompletionInd: {
    type: "radio",
    name: "delivery_completion_ind",
    data: null
  }
};

export default laoImportOrderDetailsFilters;