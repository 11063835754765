import React, { useEffect, isValidElement } from "react";
import { useTranslation } from "react-i18next"
import { get } from "lodash";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import KCChip from "./Chip";
import { saveFilters, saveSelfAlertFilters } from "../../reduxLib/services";
import moment from "moment-timezone";
import * as _ from "lodash";
import GreaterThanEqualTo from "../../assets/images/greater_than_equal_to.svg";
import LesserThanEqualTo from "../../assets/images/less_than_equal_to.svg";

const useStyles = makeStyles((theme) => ({
    root: {},
    chip: {
        border: `1px solid ${theme.palette.primary.contrastText}`,
        height: 35,
        backgroundColor: theme.palette.chip?.tableChips,
        margin: theme.spacing(0.5),
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(1)
        }
    }
}));

export const getData = (data, locations) => {
    if (data.type === "radio") {
        return {
            label: `${data.name} - ${data.data === "Y" ? "Yes" : "No"}`
        }
    }
    // if (data.type === "datetime") { 
    //     let { startTime, endTime } = data.data || {};
    //     startTime = moment(startTime).format("DD-MMM-YYYY HH:mm");
    //     endTime = moment(endTime).format("DD-MMM-YYYY HH:mm");

    //     return {
    //         label: `${data.shortName || ''} - ${startTime || ''}, ${endTime || ''}`
    //     }
    // }
    if ((data.type === "customdaterange" || data.type === "daterange") && data.data) {
        let { startTime, endTime } = data.data;
        const isRangeEnabled = Object.keys(data.data).includes("from");
        if (isRangeEnabled) {
            const name = data.name.match(/\b\w/g).join('')
            const fromSign = data.data.from >=0 ? "+": "";
            const toSign = data.data.to >=0 ? "+": "";
            return {
                label: <>
                    <span title={data.name}>{name || ''}</span> 
                    {` | From(Today) ${fromSign}${data.data.from} Days | To(Today) ${toSign}${data.data.to} Days`}
                </>
            }
        }
        
        startTime = moment(startTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
        endTime = moment(endTime, "YYYY-MM-DD").format("DD-MMM-YYYY");

        return {
            label: `${data.shortName || ''} - ${startTime || ''}, ${endTime || ''}`
        }
    }

    if (data.type === "date") {
        let { startTime, endTime } = data.data || {};
        startTime = moment(startTime, "YYYY-MM-DD").format("DD-MMM-YYYY");
        endTime = moment(endTime, "YYYY-MM-DD").format("DD-MMM-YYYY");

        return {
            label: `${data.shortName || ''} - ${startTime || ''}, ${endTime || ''}`
        }
    }
    if (data.type === "sites") {
        let label = _.find(locations, d => d.siteNum === data.data?.[data.data.length - 1]);
        return {
            label: `${data.name} - ${label?.shortName} ${(data.data?.length - 1) > 0 ? '+' + (data.data?.length - 1) : ''}`
        }
    }
    if (data.type === "text") {
        const textLabelArray = data?.data || []
        let label = "";
        if (data.stringToArray) {
            label = textLabelArray.length > 2 ? `${textLabelArray[0]}, ${textLabelArray[1]} + ${textLabelArray.length - 2} more` : textLabelArray
        } else {
            label = data?.data || "";
        }
        return {
            label: `${data.name} - ${data.data === "Y" ? "Yes" : label}`
        }
    }
    if (data.type === "textcheck") {
        const textCheckLabelArray = data?.data || []
        let label = "";
        if (data.stringToArray) {
            label = textCheckLabelArray.length > 2 ? `${textCheckLabelArray[0]}, ${textCheckLabelArray[1]} + ${textCheckLabelArray.length - 2} more` : textCheckLabelArray
        } else {
            label = data?.data || "";
        }
        return {
            label: `${data.shortName} - ${label}`
        }
    }
    if (data.type === "checkbox" && data.shortName) {
        return {
            label: `${data.shortName || null}: ${data.name}`
        }
    }
    if (data.type === 'numrange') {
        let label = '';
        if ((data?.data?.gte && data?.data?.lte) || (data?.data?.gte === 0 && data?.data?.lte === 0) || (data?.data?.gte === 0 && data?.data?.lte)) {
            label = `${data.name} : ${data?.data?.gte}-${data?.data?.lte}`
        } else if ((data?.data?.gte && !data?.data?.lte) || data?.data?.gte === 0) {
            label = <div style={{ display: 'flex' }}>{`${data.name} : `} <div style={{ margin: '3px 6px 0px 6px' }}><img src={GreaterThanEqualTo} alt="greater_than_equal_to" /></div> {` ${data?.data?.gte}`}</div>
        } else if ((!data?.data?.gte && data?.data?.lte) || data?.data?.lte === 0) {
            label = <div style={{ display: 'flex' }}>{`${data.name} : `} <div style={{ margin: '3px 6px 0px 6px' }}><img src={LesserThanEqualTo} alt="lesser_than_equal_to" /></div> {`${data?.data?.lte}`}</div>
        }

        return {
            label
        }
    }
    return {
        label: `${data.name}`
    }
}

const FilterChips = (props) => {
    const { type, subtype, locations, checkFilterChipsLength } = props;
    const classes = useStyles();
    const [filters, setFilters] = React.useState(props.filters || {});
    const chipsdata = [];
    const { t } = useTranslation();

    const saveFilters = props.selfalert ? props.saveFiltersSF: props.saveFilters;

    useEffect(() => {
        checkFilterChipsLength(chipsdata?.filter(({ data }) => !!data))
        setFilters(props.filters || {})
    }, [props.filters])

    useEffect(() => {
        checkFilterChipsLength(chipsdata?.filter(({ data }) => !!data).length)
    }, [chipsdata])

    Object.keys(filters).map((key, index) => {
        const d = filters[key];
        if (d?.type === "checkbox" || d?.type === "checkboxradio" || d?.type === "ordercheckbox") {
            d.data.map(item => {
                chipsdata.push({ ...d, ...item, type: "checkbox", parent: type, key, data: item.checked });
                return item;
            })
        } else chipsdata.push({ ...d, parent: type, key });
        return key;
    });

    const updateFilter = (data) => {
        const updatedobject = () => {
            return filters[data.key].data.map(d => {
                if (d.value === data.value) {
                    return { ...d, checked: false }
                } else {
                    return d;
                }
            });
        }
        saveFilters({
            filter: {
                [data.key]: {
                    ...filters[data.key],
                    data: data.type === "checkbox" ? updatedobject() : null
                }
            }, type, subtype
        });
    }

    return (
        <div data-testid="filterChips" >
            {
                chipsdata.filter(d => d.data)
                    .map((data, index) => {
                        const chipdata = getData({
                            ...data,
                            name: t(data?.name)
                        }, locations);

                        return ((data.data && chipdata.label) ? <KCChip
                            key={index}
                            deleteIcon={<HighlightOffRoundedIcon data-testid="deleteIconChip" />}
                            label={
                                isValidElement(chipdata.label) ?
                                    chipdata.label : t(chipdata.label)
                            }
                            className={classes.chip}
                            onDelete={() => updateFilter(data)}
                        /> : null)
                    })
            }
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    const { subtype, selfalert } = ownProps;
    const filters = selfalert ? get(state, `selfalerting.filters`, {})
        : get(state, `options.filters.${subtype}`, {});
    return {
        filters,
        locations: state.sites.locations || [],
    }
};


const mapDispatchToProps = {
    saveFilters,
    saveFiltersSF: saveSelfAlertFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterChips);
