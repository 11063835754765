const laoOrderDetailsFilters = {
  searchStringList: {
    type: "text",
    stringToArray: true,
    name: "search",
    data: null,
    region: 'lao'
  },
  vendorNumberList: {
    type: "text",
    name: "vendor_number",
    stringToArray: true, // Multi-search field
    data: null,
    region: 'lao'
  },
  vendorPlantList: {
    type: "text",
    name: "vendor_plant",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  finalOrderDestinationIdList: {
    type: "text",
    name: "customer_code_plant",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  finalOrderDestinationNameList: {
    type: "text",
    name: "customer_name",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  exportInternationalShipperList: {
    type: "text",
    name: "international_shipper",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  exportContainerIdList: {
    type: "text",
    name: "container_id",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  materialNumList: {
    type: "text",
    name: "sku_id",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  itemDescList: { // Material Description
    type: "text",
    name: "material_description",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  materialTypeList: { // Material Type
    type: "text",
    name: "material_type",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  orderOriginCountryList: {
    type: "text",
    name: "origin_country",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  finalOrderDestinationCountryList: {
    type: "text",
    name: "destination_country",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  exportBillOfLadingList: {
    type: "text",
    name: "bill_lading",
    stringToArray: true,
    data: null,
    region: 'lao'
  },
  invoiceDateRange: {
    type: "date",
    name: "invoicing_date",
    shortName: 'Invoice Date',
    data: null
  },
  exportShipmentETDRange: {
    type: "date",
    name: "export_shipment_etd_date",
    shortName: 'KC ETD',
    data: null
  },
  exportShipmentETARange: {
    type: "date",
    name: "export_shipment_eta_date",
    shortName: 'KC ETA',
    data: null
  },
  exportKcDocumentalCutOffRange: {
    type: "date",
    name: "export_kc_documental_cutoff_date",
    shortName: 'Documental Cut Off',
    data: null
  }
};

export default laoOrderDetailsFilters;